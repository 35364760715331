'use strict';

import ContentView from './content';
import Session from '../utils/session';
import App from '../app';
import jQuery from 'jquery';
import * as ChatUi from '../utils/chatui';
import * as Templates from '../templates';
import * as Dialogs from '../utils/dialogs';
import * as QuestionManagerHelper from '../utils/questionmanagerhelper';
import * as FormHelper from '../utils/formhelper';
import I18n from "../utils/i18n";
import ShortLibraScoreView from './short_libra_score';
import Backbone from 'backbone';

// How long after an input has been given should the other side start typing?
const REACTION_TIMEOUT = 500;
// How long is the other side typing a reply?
const TYPING_TIMEOUT = 900;
// How long before the form input appears?
const INPUT_TIMEOUT = 750;

let ChatCoachQuestionView = ContentView.extend({
  template: 'chat_coach_question',
  isPublic: true,

  question: null,
  questions: null,

  ui: {
    chatLog: '.chat-container .chat-log',
    prevSubmit: '.chat-container .chat-message__navigation a.previous-question',
    nextSubmit: '.chat-container .form-submit',
    radioButton: '.chat-container input[type="radio"]',
    cancel: '.chat-container .chat-message__navigation a.cancel'
  },

  events: {
    'click @ui.prevSubmit': 'prevSubmit',
    'click @ui.nextSubmit': 'nextSubmit',
    'click @ui.radioButton': 'nextSubmit',
    'click @ui.cancel': 'cancel'
  },

  cancel: function (event) {
    event.preventDefault();

    if (Session.isLoggedIn) {
      App.router.navigate('snippet', {trigger: true});
    } else {
      let closeUrl = App.config.get('libraTestCloseTestUrl');

      if (closeUrl.length) {
        window.open(closeUrl);

      } else {
        App.router.navigate('', { trigger: true },);
      }
    }

    return false;
  },

  onRender: function (options) {
    if (!Session.isLoggedIn) {
      App.refreshHeader({showHeader: false});
    }

    ChatUi.addLoadingMessage(this.ui.chatLog);

    let view = this;
    window.setTimeout(function() {
      // Get first question.
      let questionManager = view.getOption('questionManager');
      questionManager.getFirstQuestion()
        .then(
          function (question) {
            view.question = question;
            // Get all questions for the progress bar
            view.renderQuestion();
            view.updateProgressBar();
          },
          function () {
            ChatUi.removeLoadingMessage(view.ui.chatLog);
            Dialogs.alert('Er is een fout opgetreden bij het starten van de test.');
          }
        );
    }, TYPING_TIMEOUT);
  },

  renderQuestion: function () {
    this.question.updateOptions();
    let templateContext = jQuery.extend({}, this.templateContext, this.question.attributes);
    let questionClass = this.getQuestionIdClass(this.question);

    // Render message text.
    let message = Templates['chat_question_message'](templateContext);
    let messageClasses = ['chat-message--scroll', questionClass];
    if (message.indexOf('responsive-embed') !== -1) {
      messageClasses.push('chat-message--video');
    }
    ChatUi.replaceLoadingMessage(this.ui.chatLog, message, messageClasses);

    this.$el.find('.chat-container').fitVids();

    let view = this;
    window.setTimeout(function () {
      // Render question input.
      let question = Templates['chat_question_input'](templateContext);
      let questionClasses = [
        'chat-message--input',
        'chat-message--question',
        questionClass,
        'chat-message--question-' + ChatCoachQuestionView.QUESTION_TYPE_CLASSES[view.question.get('type')]
      ];
      ChatUi.addClientMessage(view.ui.chatLog, question, questionClasses);

      if (view.shouldShowSubmitButton()) {
        // Render submit button.
        let submit = Templates['chat_question_submit'](templateContext);
        let submitClasses = ['chat-message--input', 'chat-message--submit', questionClass];
        ChatUi.addClientMessage(view.ui.chatLog, submit, submitClasses);
      }

      FormHelper.attachElement(view.$el.find('.chat-container'));
      view.updateMessageNavigation();
    }, INPUT_TIMEOUT);
  },

  prevSubmit: function (event) {
    event.preventDefault();

    if (!this.question.get('is_first')) {
      let view = this;
      ChatUi.addLoadingMessage(view.ui.chatLog);

      window.setTimeout(function () {
        let questionManager = view.getOption('questionManager');
        questionManager.getPreviousQuestion()
          .then(
            function (question) {
              if (question === null) {
                if (!Session.isLoggedIn) {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  App.router.navigate('#short-libra-score', {trigger: true});
                } else {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  Backbone.fetchCache.clearItem('v2/active_session');
                  Backbone.fetchCache.clearItem('v2/user_theme_progress');
                  App.router.navigate('#snippet', {trigger: true});
                }
              } else {
                // Remove current question and answer.
                ChatUi.removeMessage(view.ui.chatLog, '.' + view.getQuestionIdClass(view.question));

                view.question = question;

                // Remove previous question and answer and re-render it.
                ChatUi.removeMessage(view.ui.chatLog, '.' + view.getQuestionIdClass(view.question));

                view.renderQuestion();
                view.updateProgressBar();
              }
              // Update progress bar
            },
            function () {
              ChatUi.removeLoadingMessage(view.ui.chatLog);
              Dialogs.alert('Er is een fout opgetreden bij het navigeren.');
            }
          );
      }, TYPING_TIMEOUT);
    }

    return false;
  },

  nextSubmit: function (event) {
    event.preventDefault();
    let questionManager = this.getOption('questionManager');

    let answer = QuestionManagerHelper.getSubmittedAnswerValue(this.question, this.$el);
    if (answer.error) {
      Dialogs.alert(answer.error);
      return false;
    }

    this.setAnsweredQuestion(this.question, answer);

    let view = this;
    window.setTimeout(function () {
      ChatUi.addLoadingMessage(view.ui.chatLog);

      window.setTimeout(function () {
        questionManager.getNextQuestion(answer)
          .then(
            function (question) {
              if (question === null) {
                if (!Session.isLoggedIn) {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  console.log(questionManager.getResults());
                  App.router.navigate('#short-libra-score');
                  App.goto(new ShortLibraScoreView({
                    results: questionManager.getResults()
                  }));
                } else {
                  ChatUi.removeLoadingMessage(view.ui.chatLog);
                  Backbone.fetchCache.clearItem('v2/active_session');
                  Backbone.fetchCache.clearItem('v2/user_theme_progress');
                  App.router.navigate('#snippet', {trigger: true});
                }
              } else {
                view.question = question;
                view.renderQuestion();
              }

              // Update progress bar
              view.updateProgressBar();
            },
            function () {
              ChatUi.removeLoadingMessage(view.ui.chatLog);
              Dialogs.alert(I18n.get('error_send_answer'));
            }
          );
      }, TYPING_TIMEOUT);
    }, REACTION_TIMEOUT);

    return false;
  },
  updateProgressBar: function () {
    let view = this;
    let questionManager = this.getOption('questionManager');
    questionManager.getQuestions()
      .then(function (questions) {
        ChatUi.updateProgressBar(view.ui.chatLog, '#question-progress-bar', questions, view.question);
      });
  },

  setAnsweredQuestion: function (question, answer) {
    ChatUi.removeMessage(this.ui.chatLog, '.chat-message--submit');

    let answerMessage = Templates['chat_question_answer']({answers: answer.displayableValue});
    let answerClasses = [this.getQuestionIdClass(question)];
    ChatUi.replaceClientMessage(this.ui.chatLog, '.chat-message--question', answerMessage, answerClasses);
  },

  shouldShowSubmitButton: function () {
    return this.question.get('type') !== 'message' &&
      this.question.get('type') !== 'multipleChoice';
  },

  updateMessageNavigation: function () {
    let messageNavigation = this.$el.find('.chat-container .chat-message__navigation');
    let previousQuestionShown = true;
    let cancelShown = true;
    if (this.question.get('is_first')) {
      messageNavigation.find('.previous-question').hide();
      previousQuestionShown = false;
    } else {
      messageNavigation.find('.previous-question').show();
    }

    if (Session.isLoggedIn) {
      messageNavigation.find('.cancel').hide();
      cancelShown = false;
    } else {
      messageNavigation.find('.cancel').show();
    }

    if (previousQuestionShown && cancelShown) {
      messageNavigation.find('span').show();
      messageNavigation.show();
    } else if (previousQuestionShown || cancelShown) {
      messageNavigation.find('span').hide();
      messageNavigation.show();
    } else {
      messageNavigation.hide();
    }
  },

  getQuestionIdClass: function (question) {
    return 'chat-message--question-' + question.get('id');
  }
});

/**
 * Question type classes
 */
ChatCoachQuestionView.QUESTION_TYPE_CLASSES = {
  'message': 'message',
  'multipleChoice': 'multiple-choice',
  'number': 'number',
  'slider': 'slider',
  'scale': 'scale',
  'text': 'text',
  'date': 'date',
  'multiSelect': 'multi-select',
};

export default ChatCoachQuestionView;
