'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'v2/config';
  },

  defaults: {
    themeCategories: [],
    libraTestCloseTestUrl: '',
    campaignImage: '',
    campaignTitle: '',
    campaignBody: '',
  }

});
