'use strict';

import ContentView from '../views/content';

import App from '../app';

import UserModel from '../models/user';

import _ from 'underscore';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import FormMixin from '../utils/formmixin.js';
import I18n from '../utils/i18n';
import Validator from 'validatorjs';

import jQuery from 'jquery';

let ProfileView = ContentView.extend({
  template: 'profile',
  isPublic: true,

  userModel: null,
  edit: false,
  originalEmail: null,

  ui: {
    form: 'form.user-form',
    name: '#name',
    email: '#email',
    email_notifications: '#email_notifications',
    zipcode: '#zipcode',
    existing_password: '#existing_password',
    password: '#password',
    password_confirmation: '#password_confirmation',
    submit: 'button[type="submit"]',
    delete: '#profile-delete',
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.delete': 'delete',
  },

  initialize: function () {
    var self = this;
    Session.getProfile().then(function (user) {
      self.userModel = user;
      self.edit = true;
      self.render();
    }, function () {
      self.userModel = null;
      self.edit = false;
    });
  },

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'profile'});
  },

  getTemplateVarsFromModel: function () {
    let data = {
      edit: Session.isLoggedIn,
      name: '',
      email: '',
      zipcode: '',
      email_notifications: false,
    };

    // Fill data from existing model.
    if (this.userModel) {
      data = jQuery.extend({}, data, this.userModel.attributes);
    }

    return data;
  },

  submit: function (event) {
    event.preventDefault();

    App.isBusy(true);
    let profileView = this;

    // Validate form
    let validation = new Validator(this.getFormValues(this.ui.form), this.getFormValidationRules());
    validation.setAttributeNames(this.getFormValidationAttributeNames());
    if (validation.passes()) {

      // Update model.
      if (!this.edit) {
        this.userModel = new UserModel();
      } else if (this.originalEmail === null) {
        this.originalEmail = this.userModel.get('email');
      }

      this.userModel.set({
        name: this.ui.name.val(),
        zipcode: this.ui.zipcode.val(),
        email_notifications: this.ui.email_notifications.prop('checked'),
      });

      let saveOptions = {};
      if (!this.edit) {

        // Fill data from pre registration form.
        const preRegisterData = window.localStorage.getItem('pre_register');
        if (preRegisterData) {
          const preRegister = JSON.parse(preRegisterData);
          this.userModel.set({
            name: preRegister.name,
            username: preRegister.email,
            email: preRegister.email,
          });
        }

        this.userModel.set({
          pass: this.ui.password.val(),
          email_notifications: true,
        });

      } else {
        saveOptions.patch = true;
        this.userModel.set({
          existing_pass: '',
          pass: ''
        });
        if (this.ui.password.val() || this.originalEmail !== this.ui.email.val()) {
          this.userModel.set({
            email: this.ui.email.val(),
            existing_pass: this.ui.existing_password.val()
          });
          if (this.ui.password.val()) {
            this.userModel.set({
              pass: this.ui.password.val()
            });
          }
        }
      }

      this.userModel.save(undefined, saveOptions)
        .done(function () {
          profileView.originalEmail = null;
          App.clearCache();
          App.isBusy(false);
          if (profileView.edit) {
            App.router.navigate('', { trigger: true });
          } else {
            // Re-login user
            Session.login(profileView.userModel.get('email'), profileView.userModel.get('pass'))
              .then(
                function (loginData) {

                  // Clear out pre registration data.
                  window.localStorage.removeItem('pre_register');

                  if (profileView.getOption('sid')) {
                    // Associate LIBRA submission with this user.
                    Session.request('POST', 'webform_associate', {sid: profileView.getOption('sid')}, {})
                      .done(function () {
                        App.router.navigate('#snippet', {trigger: true});
                      })
                      .fail(function () {
                        App.router.navigate('#snippet', {trigger: true});
                      });
                  } else {
                    App.router.navigate('#snippet', {trigger: true});
                  }
                },
                function (httpStatus, error) {
                  Dialogs.alert(I18n.get('error_login_failed'));
                  App.isBusy(false);
                }
              );
          }
        })
        .fail(function (xhr, status, error) {
          App.isBusy(false);
          if (xhr.status === 422 || xhr.status === 400) {
            let response = xhr.responseJSON;
            if (xhr.status === 400) {
              response = {
                message: xhr.responseJSON
              };
            }
            let errors = profileView.getErrorResponseDisplayableErrors(response);
            if (errors !== null) {
              Dialogs.alert(errors);
            } else {
              Dialogs.alert(I18n.get('error_profile_save_failed'));
            }
          } else {
            Dialogs.alert(I18n.get('error_profile_save_failed'));
          }
        });
    } else {
      Dialogs.alert(this.getFormDisplayableErrors(validation.errors));
      App.isBusy(false);
    }

    return false;
  },

  delete: function (event) {
    event.preventDefault();

    const self = this;

    Dialogs.confirm(I18n.get('profile_delete_confirm'))
      .then(function(result) {
        if (result !== 1) {
          return;
        }

        App.isBusy(true);
        Session.request('DELETE', 'v2/user/' + self.userModel.get('uid'))
          .done(function() {
            Session.logout()
              .then(function() {
                App.clearCache();
                App.router.navigate('', {trigger: true});
              });
          })
          .fail(function(e) {

            // Even though 200 is returned we end up in this failure callback??
            if (e.status === 200) {
              Session.logout()
                .then(function() {
                  App.clearCache();
                  App.router.navigate('', {trigger: true});
                });

            } else {
              Dialogs.alert(I18n.get('profile_delete_error'));
            }
          })
          .always(function() {
            App.isBusy(false);
          });
      });

    return false;
  },

  getFormValidationRules: function () {
    let rules = {
      password: [],
    };

    if (!this.edit) {
      rules.password.push('required');
    } else {
      rules.name = 'required';
      rules.email = 'required|email';
    }
    rules.zipcode = 'required';
    rules.password.push('min:8');
    rules.password.push('confirmed');

    return rules;
  },

  getFormValidationAttributeNames: function () {
    return {
      name: I18n.get('name'),
      email: I18n.get('email'),
      password: I18n.get('password'),
    };
  }
});

_.extend(ProfileView.prototype, FormMixin);

export default ProfileView;
