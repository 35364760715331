'use strict';

import Backbone from 'backbone';
import _ from 'underscore';

import QuestionModel from '../models/question';

import LibraQuestionManager from '../utils/libra_questionmanager';

const DIRECTION_PREVIOUS = 0;
const DIRECTION_NEXT = 1;

export default Backbone.Collection.extend({
  model: QuestionModel,

  url: function () {
    return 'webform_rest/' + encodeURIComponent(this.sessionId) + '/elements';
  },

  parse: function (response) {
    let elements = [];
    let questionManager = new LibraQuestionManager();
    let id = 0;

    for (let i in response) {
      if (response.hasOwnProperty(i) && i.substr(0, 1) !== '#') {
        let element = response[i];
        // If this an element we know about?
        if (typeof element['#type'] === 'undefined') {
          continue;
        }
        let type = questionManager.getQuestionType(element['#type']);
        if (type !== null) {
          let question = {
            is_first: id === 0,
            id: id++,
            key: i,
            type: type,
            states: element['#libra_states'],
          };
          if (type === 'message') {
            question.text = element['#markup'];
          } else {
            question.question = element['#title'];
            question.required = element['#required'];
            question.answer = element['#value'];
            if (_.isObject(question.answer) && !_.isArray(question.answer)) {
              question.answer = _.values(question.answer);
            }
            if (typeof element['#pattern'] !== 'undefined') {
              question.regex_pattern = element['#pattern'];
            }
            if (typeof element['#min'] !== 'undefined') {
              question.min_value = element['#min'];
            }
            if (typeof element['#max'] !== 'undefined') {
              question.max_value = element['#max'];
            }
            if (typeof element['#description'] !== 'undefined' && element['#description']['#markup'] !== 'undefined') {
              question.description = element['#description']['#markup'];
            }
          }

          if (type === 'multipleChoice' || type === 'multiSelect') {
            let value = question.answer;
            if (typeof value === 'string') {
              value = [value];
            }
            question.options = [];
            if (typeof element['#options'] !== 'undefined') {
              for (let j in element['#options']) {
                if (element['#options'].hasOwnProperty(j)) {
                  question.options.push({
                    id: j,
                    text: element['#options'][j],
                    checked: _.indexOf(value, j) !== -1,
                  });
                }
              }
            }
          }

          if (type === 'multiSelect' && typeof element['#options_none'] !== 'undefined' && !!element['#options_none'] && typeof element['#options_none_value'] === 'string') {
            question.none_above_value = element['#options_none_value'];
          }

          elements.push(question);
        }
      }
    }

    return this._setProgress(elements);
  },

  _setProgress: function (elements) {
    for (let i in elements) {
      elements[i].progress = Math.floor((i / elements.length) * 100);
    }
    return elements;
  },

  getPreviousVisibleQuestion: function (currentQuestionId) {
    return this._getPrevNextVisibleQuestion(currentQuestionId, DIRECTION_PREVIOUS);
  },

  getNextVisibleQuestion: function (currentQuestionId) {
    return this._getPrevNextVisibleQuestion(currentQuestionId, DIRECTION_NEXT);
  },

  _getPrevNextVisibleQuestion: function (currentQuestionId, direction) {
    let questionId = currentQuestionId;
    let question = null;
    do {
      questionId = (direction === DIRECTION_NEXT ? questionId + 1 : questionId - 1);

      // Is this a valid question index?
      if (questionId < 0 || questionId >= this.length) {
        break;
      }

      // Does the question have visible states?
      question = this.at(questionId);
      let states = question.get('states');
      if (typeof states.visible !== 'undefined' && !this.haveStateConditionsBeenMet(states.visible)) {
        question = null;
      }
    } while (!question);

    return question;
  },

  haveStateConditionsBeenMet: function (state) {
    let questionResult;
    let metCount = 0;
    for (let i in state.conditions) {
      if (state.conditions.hasOwnProperty(i)) {
        questionResult = this._haveQuestionConditionsBeenMet(i, state.conditions[i]);
        if (questionResult) {
          metCount++;
        }
        if (questionResult && state.operator === 'or') {
          return true;
        } else if (!questionResult && state.operator === 'and') {
          return false;
        } else if (state.operator === 'xor' && metCount > 1) {
          return false;
        }
      }
    }

    if (state.operator === 'xor') {
      return metCount === 1;
    } else {
      return state.operator === 'and';
    }
  },

  _haveQuestionConditionsBeenMet: function (questionKey, conditions) {
    // questionKey can contain an index between brackets.
    let key = questionKey;
    let index = null;
    let matches = questionKey.match(/^(.+)\[([0-9]+)\]$/);
    if (matches) {
      key = matches[1];
      index = matches[2]
    }

    // Get question.
    let question = this.getQuestionByKey(key);
    if (!question) {
      return false;
    }

    // Get answer we should check.
    let answer = question.get('answer');

    // Perform check.
    if (typeof conditions.checked !== 'undefined' && conditions.checked) {
      if (index !== null) {
        if (typeof answer !== 'string') {
          return _.indexOf(answer, index) !== -1;
        }
        return false;
      } else {
        return !!answer;
      }
    } else if (typeof conditions.value === 'string') {
      return conditions.value === answer;
    } else if (typeof conditions['!value'] === 'string') {
      return conditions['!value'] !== answer;
    } else if (typeof conditions.value === 'object') {
      if (typeof conditions.value.greater_equal !== 'undefined') {
        return answer >= conditions.value.greater_equal;
      } else if (typeof conditions.value.less_equal !== 'undefined') {
        return answer <= conditions.value.less_equal;
      } else if (typeof conditions.value.greater !== 'undefined') {
        return answer > conditions.value.greater;
      } else if (typeof conditions.value.less !== 'undefined') {
        return answer < conditions.value.less;
      } else if (typeof conditions.value.pattern === 'string') {
        let re = new RegExp(conditions.value.pattern);
        return re.test(answer);
      } else if (typeof conditions.value['!pattern'] === 'string') {
        let re = new RegExp(conditions.value['!pattern']);
        return !re.test(answer);
      }
      return false;
    }

    return false;
  },

  getQuestionByKey: function (key) {
    for (let i = 0; i < this.length; i++) {
      if (this.at(i).get('key') === key) {
        return this.at(i);
      }
    }
    return null;
  },

  getAnswers: function () {
    let answers = {};
    for (let i = 0; i < this.length; i++) {
      let question = this.at(i);
      answers[question.get('key')] = question.get('answer');
    }
    return answers;
  },

  sessionId: ''
});
