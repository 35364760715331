'use strict';

import HomeView from './views/home';
import InfoView from './views/info';
import LoginView from './views/login';
import ForgotPasswordView from './views/forgot_password';
import ResetPasswordView from './views/reset_password';
import ProfileView from './views/profile';
import CoachQuestionView from './views/coach_question';
import ChatCoachQuestionView from './views/chat_coach_question';
import ShortLibraScoreView from './views/short_libra_score';
import SnippetView from './views/snippet';
import ThemesView from './views/themes';
import ThemeDetailView from './views/theme_detail';

import HomeModel from './models/home';
import InfoMenuItemCollection from './collections/info_menu_items';
import ThemeProgressItemCollection from './collections/theme_progress_items';
import ThemeModel from './models/theme.js';
import ActiveSessionModel from './models/active_session';

import Session from './utils/session';
import * as Dialogs from './utils/dialogs';
import * as QuestionManagerHelper from './utils/questionmanagerhelper';
import I18n from './utils/i18n';

import App from './app';
import PreRegisterView from "./views/pre_register";

export default {
  // Home
  home: function () {
    if (Session.isLoggedIn) {
      App.router.navigate('#snippet', {trigger: true});
    } else {
      App.goto(new HomeView({
        model: new HomeModel()
      }));
    }
  },

  // Info page
  info: function () {
    App.goto(new InfoView({
      collection: new InfoMenuItemCollection()
    }));
  },

  // Login
  login: function () {
    if (Session.isLoggedIn) {
      App.router.navigate('#snippet', {trigger: true});
    } else if (App.kioskMode) {
      App.router.navigate('#home', {trigger: true});
    } else {
      App.goto(new LoginView());
    }
  },

  // Logout
  logout: function () {
    App.isBusy(true);

    Session.logout().then(
      function () {
        App.clearCache();
        Dialogs.alert(I18n.get('you_are_logged_out'));
        App.router.navigate('', {trigger: true});
      }
    ).then(function () {
      App.isBusy(false);
    });
  },

  // Forgot my password
  forgotPassword: function () {
    App.goto(new ForgotPasswordView());
  },

  // Reset password.
  resetPassword: function (email, tempPassword) {
    App.goto(new ResetPasswordView({
      mail: email,
      tempPassword: tempPassword
    }));
  },

  // User registration.
  register: function (sid) {
    App.goto(new ProfileView({
      sid: sid,
    }));
  },

  // User profile edit.
  profile: function () {
    App.goto(new ProfileView());
  },

  // Nut of the day
  snippet: function () {
    App.goto(new SnippetView({
      model: new ActiveSessionModel()
    }));
  },

  // Start-up short (anonymous) LIBRA test
  shortLibra: function () {
    if (Session.isLoggedIn) {
      Dialogs.alert(I18n.get('short_test_restrict'));
    } else {
      App.router.navigate('#session/short_libra_test', {trigger: true});
    }
  },

  // Score of short LIBRA test
  shortLibraScore: function () {
    //let model = new CareVariablesModel({userId: Session.userId});
    App.goto(new ShortLibraScoreView({
      //model: model
    }));
  },

  startCoachSession: function (id) {
    App.isBusy(true);

    let questionManager = QuestionManagerHelper.getInstance(id, false);
    if (questionManager.useChatUi) {
      App.goto(new ChatCoachQuestionView({
        questionManager: questionManager,
        sessionId: id
      }));
    } else {
      // Get first question.
      questionManager.getFirstQuestion()
        .then(
          function (question) {
            App.goto(new CoachQuestionView({
              model: question,
              questionManager: questionManager,
              sessionId: id
            }));
          },
          function () {
            Dialogs.alert(I18n.get('error_start_test'));
            App.isBusy(false);
          }
        );
    }
  },

  coachQuestion: function (id, question) {
  },

  viewCoachSession: function (id) {
    if (!Session.isLoggedIn) {
      Dialogs.alert('Je moet ingelogd zijn om een test te bekijken.');
      return;
    }

    App.isBusy(true);

    let questionManager = QuestionManagerHelper.getInstance(id, true);
    questionManager.getFirstQuestion()
      .then(
        function (question) {
          App.goto(new CoachQuestionView({
            model: question,
            questionManager: questionManager,
            sessionId: id
          }));
        },
        function () {
          Dialogs.alert('Er is een fout opgetreden bij het bekijken van de test.');
          App.isBusy(false);
        }
      );
  },

  // Themes overview
  themes: function () {
    App.goto(new ThemesView({
      collection: new ThemeProgressItemCollection()
    }));
  },

  // Theme single
  themeDetail: function (id) {
    App.goto(new ThemeDetailView({
      model: new ThemeModel({id: id})
    }));
  },

  // Pre-registration
  preregister: function () {
    App.goto(new PreRegisterView());
  },
};
