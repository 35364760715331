'use strict';

import ContentView from '../views/content';

import App from '../app';

import _ from 'underscore';
import * as Dialogs from '../utils/dialogs';
import FormMixin from '../utils/formmixin.js';
import I18n from '../utils/i18n';
import Validator from 'validatorjs';

import jQuery from 'jquery';
import Session from '../utils/session';

let PreRegisterView = ContentView.extend({
  template: 'pre_register',
  isPublic: true,

  ui: {
    form: 'form.subscribe-form',
    name: '#name',
    email: '#email',
    privacy_policy_approved: '#privacy_policy_approved',
    submit: 'button[type="submit"]'
  },

  events: {
    'click @ui.submit': 'submit'
  },

  initialize: function () {
  },

  onBeforeRender: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
  },

  onRender: function () {
    App.refreshHeader({
      showBackButton: true,
      activeTab: 'profile'
    });
  },

  getTemplateVarsFromModel: function () {
    return {
      name: '',
      email: '',
    };
  },

  submit: function (event) {
    event.preventDefault();

    App.isBusy(true);

    let validation = new Validator(this.getFormValues(this.ui.form), this.getFormValidationRules());
    validation.setAttributeNames(this.getFormValidationAttributeNames());
    if (!validation.passes()) {
      Dialogs.alert(this.getFormDisplayableErrors(validation.errors));
      App.isBusy(false);
      return false;
    }

    const name = this.ui.name.val();
    const email = this.ui.email.val();

    // Store data for later.
    window.localStorage.setItem('pre_register', JSON.stringify({
      name: name,
      email: email,
    }));

    // Subscribe user to mailing list before starting short libra test.
    Session.request('POST', 'v2/mailing_list/subscribe', {
      name: name,
      email: email,
    }, {})
      .done(function () {
        App.isBusy(false);
        App.router.navigate('#short-libra', {trigger: true});
      })
      .fail(function (response) {
        App.isBusy(false);

        if (response.responseJSON && response.responseJSON.error === 'email_in_use') {
          Dialogs.alert(I18n.get('pre_register_mail_in_use'));
        } else {
          Dialogs.alert(I18n.get('pre_register_save_failure'));
        }
      });

    return false;
  },

  getFormValidationRules: function () {
    return {
      name: 'required',
      email: 'required|email',
      privacy_policy_approved: 'required',
    };
  },

  getFormValidationAttributeNames: function () {
    return {
      name: I18n.get('name'),
      email: I18n.get('email'),
      privacy_policy_approved: I18n.get('privacy_consent'),
    };
  }
});

_.extend(PreRegisterView.prototype, FormMixin);

export default PreRegisterView;
