'use strict';

import Session from '../utils/session';
import LibraElementsCollection from '../collections/libra_elements';

export default class {
  constructor (sessionId, isCompletedSession) {
    this.sessionId = sessionId;
    this.isCompletedSession = isCompletedSession;
    this.currentQuestionId = -1;
    this.elements = null;
    this.answers = {};
    this.results = null;
  }

  getQuestions () {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.isCompletedSession) {
        reject();
      } else {
        self._getElements()
        .then((questions) => {
          resolve(questions);
        });
      }
    });
  }

  getFirstQuestion () {
    let self = this;
    this.currentQuestionId = -1;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        reject();
      } else {
        self._getElements()
          .then(
            function (questions) {
              let question = questions.getNextVisibleQuestion(self.currentQuestionId);
              if (question) {
                self.currentQuestionId = question.get('id');
                resolve(question);
              } else {
                reject();
              }
            },
            function () {
              reject();
            }
          );
      }
    });
  }

  getPreviousQuestion () {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        reject();
      } else {
        if (self.currentQuestionId === 0) {
          reject();
        } else {
          self._getElements()
            .then(
              function (questions) {
                let question = questions.getPreviousVisibleQuestion(self.currentQuestionId);
                if (question) {
                  self.currentQuestionId = question.get('id');
                  resolve(question);
                } else {
                  reject();
                }
              },
              function () {
                reject();
              }
            );
        }
      }
    });
  }

  getNextQuestion (answer) {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        reject();
      } else {
        self._getElements()
          .then(
            function (questions) {
              // Register answer.
              let question = questions.at(self.currentQuestionId);
              question.set({answer: answer.value});
              // Retrieve next one.
              let nextQuestion = questions.getNextVisibleQuestion(self.currentQuestionId);
              if (nextQuestion) {
                self.currentQuestionId = nextQuestion.get('id');
                resolve(nextQuestion);
              } else {
                // Submit all answers.
                let answers = questions.getAnswers();
                answers.webform_id = self.sessionId;
                Session.request('POST', 'webform_rest/submit', answers)
                  .done(function (data) {
                    self.results = data;
                    resolve(null);
                  })
                  .fail (function () {
                    reject();
                  });
              }
            },
            function () {
              reject();
            }
          );
      }
    });
  }

  getResults () {
    return this.results;
  }

  _getElements () {
    console.log('Retrieving elements');
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.elements !== null) {
        console.log('Retrieved using cache');
        resolve(self.elements);
      } else {
        let collection = new LibraElementsCollection();
        collection.sessionId = self.sessionId;

        collection.fetch()
          .done(function () {
            console.log('Elements fetch done');
            self.elements = collection;
            resolve(self.elements);
          })
          .fail(function () {
            console.log('Elements fetch failed');
            reject();
          });
      }
    });
  }

  get useChatUi () {
    return true;
  }

  /**
   * Question types.
   */
  get QUESTION_TYPES() {
    return {
      'message': 'webform_markup',
      'multipleChoice': 'radios',
      'number': 'number',
      'slider': null,
      'scale': null,
      'text': 'textfield',
      'date': null,
      'multiSelect': 'checkboxes',
    };
  }

  getQuestionType (name) {
    for (let i in this.QUESTION_TYPES) {
      if (this.QUESTION_TYPES[i] === name) {
        return i;
      }
    }
    return null;
  }

}
