'use strict';

import ContentView from '../views/content';

import App from '../app';
import jQuery from 'jquery';
import I18n from '../utils/i18n';

export default ContentView.extend({
  template: 'short_libra_score',
  isPublic: true,
  kioskTimer: null,
  resultsReadyHeading: '',

  ui: {
    filters: '.filter',
  },

  events: {
    'click @ui.filters': 'filter',
  },

  onAttach: function () {
    if (App.kioskMode) {
      this.kioskTimer = window.setTimeout(function () {
        App.router.navigate('#home', {trigger: true});
      }, 120000);
    }

    requestAnimationFrame(function() {
      jQuery('html').get(0).scrollTop = 0;
    });
  },

  onDetach: function () {
    if (this.kioskTimer) {
      window.clearTimeout(this.kioskTimer);
    }
  },

  onRender: function () {
    App.refreshHeader({showHeader: false});
  },

  onBeforeRender: function () {
    jQuery('html').get(0).scrollTop = 0;
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVars());
  },

  getTemplateVars: function () {
    const values = {
      themes: [],
      types: {
        improve: {
          label: I18n.get('theme_type_improve'),
          heading: I18n.get('theme_type_improve_heading'),
          description: I18n.get('theme_type_improve_description'),
          hidden: true,
          weight: 10,
        },
        monitor: {
          label: I18n.get('theme_type_monitor'),
          heading: I18n.get('theme_type_monitor_heading'),
          description: I18n.get('theme_type_monitor_description'),
          hidden: true,
          weight: 20,
        },
        maintain: {
          label: I18n.get('theme_type_maintain'),
          heading: I18n.get('theme_type_maintain_heading'),
          description: I18n.get('theme_type_maintain_description'),
          hidden: true,
          weight: 30,
        },
      },
      activeTypeKey: 'improve',
      activeType: {},
      kioskMode: App.kioskMode,
      resultsReadyHeading: App.config.get('campaignTitle'),
      registerForMore: App.config.get('campaignBody'),
      logo: App.config.get('campaignImage'),
      resultsReadyButton: App.config.get('campaignButtonText'),
      resultsReadyButtonUrl: App.config.get('campaignButtonUrl'),
      resultsReadyOutro: App.config.get('campaignBodyOutro'),
    };

    const results = this.getOption('results');
    if (typeof results.scores !== 'undefined') {
      for (const score of results.scores) {
        const type = score.score;
        values.types[type].hidden = false;

        values.themes.push({
          id: score.id,
          title: score.title,
          icon: score.icon,
          class: score.class,
          description: score.description,
          weight: score.weight,
          type: type,
        });
      }

      // Sort themes by term weight.
      values.themes.sort(function(a, b) {
        return a.weight - b.weight;
      });

      // Make first available type active.
      for (const key of Object.keys(values.types)) {
        if (!values.types[key].hidden) {
          values.activeTypeKey = key;
          values.activeType = values.types[key];
          break;
        }
      }
    }

    return values;
  },

  filter: function (event) {
    event.preventDefault();

    const element = event.currentTarget;
    const filterType = element.getAttribute('data-filter');
    const heading = element.getAttribute('data-heading');
    const description = element.getAttribute('data-description');

    this.$el.find('.panel').addClass('hidden');
    this.$el.find('.panel[data-type="' + filterType + '"').removeClass('hidden');

    this.$el.find('.filter[data-filter]').removeClass('filter--active');
    this.$el.find('.filter[data-filter="' + filterType + '"').addClass('filter--active');

    this.$el.find('#theme-type-heading').text(heading);
    this.$el.find('#theme-type-description').text(description);

    return false;
  },

});
