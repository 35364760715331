'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    if (this.attributes.uid) {
      return 'user/' + encodeURIComponent(this.attributes.uid);
    } else {
      return 'user/register';
    }
  },

  idAttribute: 'uid',
  defaults: {
    uid: null,
    username: '',
    name: '',
    email: '',
    email_notifications: true,
    existing_pass: '',
    pass: '',
    zipcode: '',
  },

  apiMapping: {
    uid: 'uid',
    username: 'name',
    name: 'field_name',
    email: 'mail',
    email_notifications: 'field_receive_email',
    pass: 'pass',
    zipcode: 'field_zipcode',
  },

  parse: function (data) {
    let transformedData = {};
    for (let i in this.apiMapping) {
      if (data.hasOwnProperty(this.apiMapping[i]) && data[this.apiMapping[i]].length > 0) {
        if (typeof data[this.apiMapping[i]][0].value !== 'undefined') {
          transformedData[i] = data[this.apiMapping[i]][0].value;
        }
      }
    }
    return transformedData;
  },

  toJSON: function () {
    let transformedData = {};
    for (let i in this.apiMapping) {
      if (i !== 'uid') {
        // Never send uid back to the server.
        transformedData[this.apiMapping[i]] = [{
          value: this.get(i)
        }];
      }
    }
    if (this.get('existing_pass')) {
      let passField = transformedData.pass ? transformedData.pass : [{}];
      passField[0].existing = this.get('existing_pass');
      transformedData.pass = passField;
    }
    return transformedData;
  }

});
