'use strict';

import jQuery from 'jquery';
import Marionette from 'backbone.marionette';

import App from '../app';

export default Marionette.View.extend({
  tagName: 'div',
  className: 'view-container',
  isPublic: false,
  modelSyncInterval: 0,

  initialize: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, {
      //user: App.user.attributes
    });
  },

  onAttach: function (options) {
    let view = this;

    if (view.modelSyncInterval) {
      view.modelTimer = setTimeout(function () {
        view.model.fetch({cache: true, expires: view.modelSyncInterval});
      }, view.modelSyncInterval * 1000);

      view.listenTo(view.model, 'sync', view.render);
      view.model.fetch({cache: true, expires: view.modelSyncInterval});
    }

    this.$el.on('click', '.clickarea', this.clickArea.bind(this));
    this.$el.on('click', 'a:not(.back-link)', this.linkNavigate.bind(this));

    jQuery('body').addClass('page-template-' + this.template);
    jQuery('html').get(0).scrollTop = 0;
  },

  onDetach: function (options) {
    if (this.modelTimer) {
      clearTimeout(this.modelTimer);
    }

    this.$el.off('click', '.clickarea', this.clickArea.bind(this));
    this.$el.off('click', 'a', this.linkNavigate.bind(this));

    jQuery('body').removeClass('page-template-' + this.template);
  },

  linkNavigate: function (event) {
    App.linkNavigation = true;
  },

  clickArea: function (event) {
    event.preventDefault();

    let element = jQuery(event.currentTarget);
    let anchor = element.find('a');
    if (anchor.length) {
      let href = anchor.attr('href');
      App.router.navigate(href, {trigger: true});
    }

    return false;
  }
});
