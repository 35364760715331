'use strict';

import ContentView from '../views/content';

import Session from "../utils/session";

import App from '../app';
import jQuery from 'jquery';
import * as Dialogs from "../utils/dialogs";
import I18n from '../utils/i18n';
import Backbone from 'backbone';

export default ContentView.extend({
  template: 'theme_detail',
  isPublic: false,

  ui: {
    activateThemeButton: '.thema-status .btn, .thema-status .is-active'
  },

  events: {
    'click @ui.activateThemeButton': 'clickActivateTheme'
  },

  initialize: function() {
    this.listenTo(this.model, 'sync', this.update);
    this.model.fetch({cache: false});
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'themes'});
  },

  update: function () {
    this.templateContext = jQuery.extend({}, this.templateContext);
    this.render();
  },

  clickActivateTheme: function (event) {
    event.preventDefault();

    let setActive = true
    if (event.currentTarget.className.includes('is-active')) {
      setActive = false
    }

    let view = this;
    App.isBusy(true);
    Session.request('PATCH', 'v2/theme/' + this.model.get('id'), {active: setActive})
      .done(function (data, status, xhr) {
        App.isBusy(false);

        Backbone.fetchCache.clearItem('v2/active_session');
        Backbone.fetchCache.clearItem('v2/user_theme_progress');

        view.templateContext['justActivated'] = true;
        view.model.fetch({cache: false});
      })
      .fail(function (xhr, status, error) {
        Dialogs.alert(I18n.get('error_save_theme'));
        App.isBusy(false);
      });

    return false;
  }

});
